import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Card, Col, Row, DatePicker } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { createVoucher, fetchAccounts } from "../services/ApiServices/apiFunctions";
import SearchableSelect from "../components/common/SearchableSelect";

interface Account {
  id: number;
  account_name: string;
  parent_id: number;
}

interface VoucherFormValues {
  pay_mode: string;
  voucher_type: number;
  cheque_no: string;
  voucher_date: string;
  description: string;
  amount?: number; // This will be calculated based on debit and credit
  account_id: number;
  debit?: number;
  credit?: number;
}

function OpeningForm() {
  const [form] = Form.useForm();
  const [adding, setAdding] = useState(false);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const handleAddVoucher = async (values: VoucherFormValues) => {
    setAdding(true);
    try {
      values.voucher_date = dayjs(values.voucher_date).format("YYYY-MM-DD");
      values.pay_mode = "CREDIT"; // Assuming pay_mode is fixed for this example
      values.voucher_type = 3; // Assuming voucher_type is fixed for this example

      // Set amount based on debit or credit
      values.amount = values.debit ? values.debit : values.credit ? -values.credit : 0;

      const response = await createVoucher(values);

      if (response.responseCode === 0) {
        message.success(response.responseDescription);
        form.resetFields();
        setSelectedDate(dayjs(values.voucher_date));
      } else {
        message.error("Failed to add opening balance");
      }
    } catch (error) {
      message.error("Failed to add opening balance");
    } finally {
      setAdding(false);
    }
  };

  const handleAccountChange = (value: number) => {
    form.setFieldsValue({ account_id: value });
  };

  // Handle changes for debit and credit
  const handleDebitChange = (value: string) => {
    const debitValue = value ? parseFloat(value) : null;
    form.setFieldsValue({ debit: debitValue, credit: null }); // Reset credit when debit changes
  };

  const handleCreditChange = (value: string) => {
    const creditValue = value ? parseFloat(value) : null;
    form.setFieldsValue({ credit: creditValue, debit: null }); // Reset debit when credit changes
  };

  // Fetch accounts when component mounts
  useEffect(() => {
    const fetchAccountsData = async (): Promise<void> => {
      try {
        const accountsData: Account[] = await fetchAccounts();
        const filteredAccounts = accountsData.filter((account) =>
          [1, 2, 3, 4].includes(account.parent_id)
        );
        setAccounts(filteredAccounts);
      } catch (error) {
        message.error("Error while fetching accounts");
      }
    };

    form.setFieldsValue({
      voucher_date: selectedDate,
      description: "OPENING",
    });
    fetchAccountsData();
  }, [form, selectedDate]);

  return (
    <Card title={"Opening Form"}>
      <Form
        form={form}
        onFinish={handleAddVoucher}
        layout="vertical"
        size="middle"
      >
        <Row gutter={24}>
          <Col xs={24} sm={8}>
            <Form.Item
              name="voucher_date"
              label="Date"
              rules={[{ required: true, message: "Date is required" }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                defaultValue={form.getFieldValue("voucher_date") ? dayjs(form.getFieldValue("voucher_date")) : selectedDate}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: "Description is required" }]}
            >
              <Input
                placeholder="Enter description"
                defaultValue={"OPENING"}
                readOnly
                style={{ backgroundColor: '#f5f5f5' }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              name="account_id"
              label="Select Account"
              rules={[{ required: true, message: "Account is required" }]}
            >
              <SearchableSelect
                options={accounts}
                placeholder="Select an Account"
                onChange={handleAccountChange}
                idKey="id"
                nameKey="account_name"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="debit"
              label="Debit Amount"
            >
              <Input
                type="number"
                placeholder="Enter debit amount"
                style={{ textAlign: "right" }}
                onChange={(e) => handleDebitChange(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="credit"
              label="Credit Amount"
            >
              <Input
                type="number"
                placeholder="Enter credit amount"
                style={{ textAlign: "right" }}
                onChange={(e) => handleCreditChange(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={adding}
            disabled={adding}
            icon={<SaveOutlined />}
            style={{ width: '100%', borderRadius: '5px' }}
          >
            Add Opening
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default OpeningForm;
