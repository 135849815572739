import React, { useState, useEffect } from "react";
import {
  Table,
  Select,
  Button,
  Space,
  Alert,
  DatePicker,
  Card,
  Col,
  Row,
  Modal,
} from "antd";
import {
  PrinterOutlined,
  EyeOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { fetchInvoicesReport, fetchContacts, deleteInvoice } from "../services/ApiServices/apiFunctions";
import dayjs from "dayjs";
import InvoiceView from "./InvoiceView";
import SearchableSelect from "../components/common/SearchableSelect";
import { Option } from "antd/es/mentions";

const { RangePicker } = DatePicker;

interface Contact {
  id: number;
  name: string;
}
interface DataItem {
  InvoiceNo: string;
  InvoiceType: string;
  Description: string | null;
  PartyName: string;
  InvoiceDate: string;
  PayMode: string;
  totalSales: string;
}
const ViewInvoices: React.FC = () => {
  const [data, setData] = useState<DataItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [grandTotal, setGrandTotal] = useState<number>(0);
  const [InvoiceType, setInvoiceType] = useState<string>("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [error, setError] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null] | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<string | null>(null);
  const [selectedInvoiceType, setSelectedInvoiceType] = useState<string>('');
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [PartyID, setPartyID] = useState<number>(0);
  const [payMode, setPayMode] = useState<string>('');

  useEffect(() => {
    loadContacts();
  }, []);


  const loadContacts = async () => {
    setLoading(true);
    try {
      const data = await fetchContacts();
      if (Array.isArray(data.responseObject)) {
        const allOption = { id: 0, name: "ALL" };
        setContacts([allOption, ...data.responseObject]);
        setError(null);
      } else {
        setError("Error: Invalid data format");
      }
    } catch (error) {
      setError("Error fetching contacts. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTableData(
      pagination.current,
      pagination.pageSize,
      dateRange,
      InvoiceType,
      PartyID,
      payMode
    );
  }, [pagination.current, pagination.pageSize, dateRange, InvoiceType, PartyID, payMode]);

  const fetchTableData = async (
    page: number,
    pageSize: number,
    dateRange: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null,
    InvoiceType: string,
    PartyID: number,
    payMode: string
  ) => {
    setLoading(true);
    setError(null);
    try {
      const fromDate = dateRange
        ? dateRange[0]?.format("YYYY-MM-DD")
        : undefined;
      const toDate = dateRange ? dateRange[1]?.format("YYYY-MM-DD") : undefined;

      const response = await fetchInvoicesReport(
        page,
        pageSize,
        fromDate,
        toDate,
        InvoiceType,
        PartyID,
        payMode
      );

      setData(response.responseObject.invoiceData.data);
      setGrandTotal(response.responseObject.totalSum);
      setPagination({
        ...pagination,
        total: response.responseObject.invoiceData.total,
      });
    } catch (error) {
      setError("An error occurred while fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  const handleDateRangeChange = (
    dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null,
    dateStrings: [string, string]
  ) => {
    setDateRange(dates);
    setPagination({ ...pagination, current: 1 });
  };

  const handleView = (record: DataItem) => {
    setSelectedInvoice(record.InvoiceNo);
    setSelectedInvoiceType(record.InvoiceType);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedInvoice(null);
    setSelectedInvoiceType('');
  };

  const handlePartyChange = (value: number) => {
    setPartyID(value);
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  const handleInvoiceType = (value: string) => {
    setInvoiceType(value);
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  const handlePayMode = (value: string) => {
    setPayMode(value);
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  const handleDelete = (record: DataItem) => {
    Modal.confirm({
      title: "Are you sure you want to delete this invoice?",
      content: `Invoice No: ${record.InvoiceNo}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteInvoice(record.InvoiceNo, record.InvoiceType);
          setData((prevData) =>
            prevData.filter((item) => item.InvoiceNo !== record.InvoiceNo)
          );
          Modal.success({
            content: `Invoice ${record.InvoiceNo} deleted successfully.`,
          });
        } catch (error) {
          Modal.error({
            content: "Failed to delete invoice. Please try again.",
          });
        }
      },
    });
  };

  const handlePrint = (record: DataItem) => {
    console.log("Printing:", record);
  };

  const columns = [
    {
      title: "Invoice No",
      dataIndex: "InvoiceNo",
      key: "InvoiceNo",
    },
    {
      title: "Invoice Type",
      dataIndex: "InvoiceType",
      key: "InvoiceType",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      render: (text: string) => (
        <div>{text ?? '--'}</div>
      ),
    },
    {
      title: "Party Name",
      dataIndex: "PartyName",
      key: "PartyName",
    },
    {
      title: "Invoice Date",
      dataIndex: "InvoiceDate",
      key: "InvoiceDate",
    },
    {
      title: "Pay Mode",
      dataIndex: "PayMode",
      key: "PayMode",
    },
    {
      title: "Amount",
      dataIndex: "TotalSales",
      key: "TotalSales",
      render: (text: string) => (
        <div style={{ textAlign: "right" }}>{text}</div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: DataItem) => (
        <Space size="middle">
          <Button icon={<EyeOutlined />} onClick={() => handleView(record)} />
          <Button
            icon={<PrinterOutlined />}
            onClick={() => handlePrint(record)}
          />
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => handleDelete(record)}
          />
        </Space>
      ),
    },

  ];

  return (
    <Card title="View Invoices">
      <InvoiceView
        invoiceNumber={selectedInvoice || ""}
        InvoiceType={selectedInvoiceType}
        visible={modalVisible}
        onClose={handleCloseModal}
      />
      {error && (
        <Alert
          message={error}
          type="error"
          closable
          style={{ marginBottom: "10px" }}
        />
      )}
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={6}>
          <label htmlFor="party-select">Party</label>
          <SearchableSelect
            options={contacts}
            placeholder="Select Party"
            onChange={handlePartyChange}
            idKey="id"
            nameKey="name"
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <label htmlFor="invoice-select">Invoice Type</label>
          <Select
            aria-labelledby="Invoice Type"
            menuItemSelectedIcon
            defaultValue=""
            style={{ width: "100%" }}
            onChange={handleInvoiceType}
            options={[
              { value: "", label: "ALL" },
              { value: "PURCHASE INVOICE", label: "PURCHASE INVOICE" },
              { value: "PURCHASE RETURN", label: "PURCHASE RETURN" },
              { value: "SALE INVOICE", label: "SALE INVOICE" },
              { value: "SALE RETURN", label: "SALE RETURN" },
            ]}
          ></Select>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <label htmlFor="pay-mode">Pay Mode</label>
          <Select
            placeholder="Product Type"
            onChange={handlePayMode}
            style={{ width: '100%' }}
            value={payMode}
          >
            <Option value="">ALL</Option>
            <Option value="CASH">CASH</Option>
            <Option value="BANK">BANK</Option>
            <Option value="CREDIT">CREDIT</Option>
          </Select>

        </Col>
        <Col xs={24} sm={12} md={6}>
          <label htmlFor="date-select">Date Range</label>
          <RangePicker
            onChange={handleDateRangeChange}
            style={{ width: "100%" }}
            placeholder={["From Date", "To Date"]}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={pagination}
            onChange={handleTableChange}
            rowKey="InvoiceNo"
            size="small"
            scroll={{ x: true }}
            bordered
            summary={(tableData) => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={5}></Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align="right">
                      <strong>Grand Total</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align="right">
                      <strong>{grandTotal}</strong>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default ViewInvoices;
