import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Card, Col, Row, DatePicker } from "antd";
import {
  BankOutlined,
  DollarCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import {
  createVoucher,
  fetchAccounts,
} from "../services/ApiServices/apiFunctions";
import SearchableSelect from "../components/common/SearchableSelect";

interface Account {
  id: number;
  account_name: string;
  parent_id: number;
}

interface VoucherProps {
  voucherType: number; // Voucher Type prop
}

interface VoucherFormValues {
  pay_mode: string;
  voucher_type: number;
  cheque_no: string;
  voucher_date: string;
  description: string;
  amount: number;
  account_id: number;
}

const Voucher: React.FC<VoucherProps> = ({ voucherType }) => {
  const [form] = Form.useForm();
  const [adding, setAdding] = useState(false);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [paymentMethod, setPaymentMethod] = useState<string | null>("CASH");
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const handlePaymentMethodChange = (method: string) => {
    setPaymentMethod(method);
    form.setFieldsValue({ pay_mode: method, voucher_type: voucherType }); // Set voucher_type
  };

  const handleAddVoucher = async (values: VoucherFormValues) => {
    setAdding(true);
    try {
      values.voucher_date = dayjs(values.voucher_date).format("YYYY-MM-DD");
      values.voucher_type = voucherType;
      const response = await createVoucher(values);

      if (response.responseCode === 0) {
        message.success(response.responseDescription);
        form.resetFields();
        setSelectedDate(dayjs(values.voucher_date));
      } else {
        message.error("Failed to add payment voucher");
      }
    } catch (error) {
      message.error("Failed to add payment voucher");
    } finally {
      setAdding(false);
    }
  };

  // Fetch accounts when component mounts
  useEffect(() => {
    const fetchAccountsData = async (): Promise<void> => {
      try {
        const accountsData: Account[] = await fetchAccounts();
        const filteredAccounts = accountsData.filter((account) =>
          [2, 3, 4].includes(account.parent_id)
        );
        setAccounts(filteredAccounts);
      } catch (error) {
        message.error("Error while fetching accounts");
      }
    };
    form.setFieldsValue({
      pay_mode: paymentMethod,
      voucher_date: selectedDate,
    });
    fetchAccountsData();
  }, [form, voucherType, paymentMethod, selectedDate]);

  return (
    <div>
      <Card
        title={
          <span>
            {voucherType === 1 ? "Payment Voucher" : "Receipt Voucher"}
          </span>
        }
      >
        <Form
          form={form}
          onFinish={handleAddVoucher}
          layout="vertical"
          size="middle"
        >
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="pay_mode"
                label="Payment Method"
                rules={[
                  { required: true, message: "Payment method is required" },
                ]}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    type={paymentMethod === "CASH" ? "primary" : "default"}
                    onClick={() => handlePaymentMethodChange("CASH")}
                    style={{ flex: 1, marginRight: "8px" }}
                  >
                    <DollarCircleOutlined style={{ marginRight: "4px" }} />
                    Cash
                  </Button>
                  <Button
                    type={paymentMethod === "BANK" ? "primary" : "default"}
                    onClick={() => handlePaymentMethodChange("BANK")}
                    style={{ flex: 1 }}
                  >
                    <BankOutlined style={{ marginRight: "4px" }} />
                    Bank
                  </Button>
                </div>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item name="cheque_no" label="Cheque Number">
                <Input placeholder="Enter cheque number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="voucher_date"
                label="Date"
                rules={[{ required: true, message: "Date is required" }]}
              >
                <DatePicker
                  defaultValue={
                    form.getFieldValue("voucher_date")
                      ? dayjs(form.getFieldValue("voucher_date"))
                      : selectedDate
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="description"
                label="Description"
                rules={[{ required: true, message: "Description is required" }]}
              >
                <Input placeholder="Enter description" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="amount"
                label="Amount"
                rules={[{ required: true, message: "Amount is required" }]}
              >
                <Input
                  type="number"
                  placeholder="Enter amount"
                  style={{ textAlign: "right" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="account_id"
                label="Select Account"
                rules={[{ required: true, message: "Account is required" }]}
              >
                <SearchableSelect
                  options={accounts}
                  placeholder="Select an Account"
                  onChange={(value) =>
                    form.setFieldsValue({ account_id: value })
                  }
                  idKey="id"
                  nameKey="account_name"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={adding}
              disabled={adding}
              icon={<SaveOutlined />}
            >
              Add {voucherType === 1 ? "Payment Voucher" : "Receipt Voucher"}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Voucher;
