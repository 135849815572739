import React, { useState, useEffect } from "react";
import { Table, Spin, Modal } from "antd";
import { fetchInvoice } from "../services/ApiServices/apiFunctions";

interface InvoiceViewProps {
  invoiceNumber: string;
  InvoiceType: string;
  visible: boolean;
  onClose: () => void;
}

interface InvoiceDetails {
  id: number;
  InvoiceNo: string;
  InvoiceType: string;
  Description: null | string;
  PartyID: number;
  PartyName: string;
  PayMode: string;
  BrandName: string;
  Draft: number;
  InvoiceDate: string;
  ProductID: number;
  PurchaseRate: number;
  Qty: number;
  Rate: number;
  Status: number;
  Total: number;
  created_at: string;
  updated_at: string;
}

function InvoiceView({
  invoiceNumber,
  InvoiceType,
  visible,
  onClose,
}: InvoiceViewProps) {
  const [loading, setLoading] = useState(true);
  const [grandTotal, setGrandTotal] = useState<number>(0);
  const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDetails[] | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    // Fetch data only when the modal is opened
    if (visible) {
      fetchDetails();
    }
  }, [visible]);

  const fetchDetails = async () => {
    try {
      setLoading(true);
      const details = await fetchInvoice(invoiceNumber, InvoiceType);
      setInvoiceDetails(details.responseObject.invoices);
      setGrandTotal(details.responseObject.grandTotal);
      setErrorMessage(null);
    } catch (error) {
      console.error("Error fetching invoice details:", error);
      setErrorMessage("Error fetching invoice details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setInvoiceDetails(null);
    setErrorMessage(null);
    onClose();
  };

  const columns = [
    {
      title: "Product",
      dataIndex: "BrandName",
      key: "BrandName",
    },
    {
      title: "Qty",
      dataIndex: "Qty",
      key: "Qty",
    },
    {
      title: "Rate",
      dataIndex: "Rate",
      key: "Rate",
    },
    {
      title: "Total",
      dataIndex: "Total",
      key: "Total",
    },
  ];

  const dataSource: InvoiceDetails[] = Array.isArray(invoiceDetails)
    ? invoiceDetails
    : [];

  // Footer to display grand total
  const footer = () => (
    <div style={{ fontWeight: "bold" }} >
      <span style={{ marginRight: 20 }} aria-colspan={3}>Grand Total:</span>
      <span>{grandTotal.toFixed(2)}</span>
    </div>
  );

  return (
    <Modal
      title={`Invoice Details - ${invoiceNumber}`}
      open={visible}
      onCancel={handleClose}
      footer={null}
    >
      {loading && <Spin tip="Loading..." />}
      {!loading && errorMessage && (
        <p style={{ color: "red" }}>{errorMessage}</p>
      )}
      {!loading && !errorMessage && !invoiceDetails && (
        <p>Error fetching invoice details</p>
      )}
      {!loading && !errorMessage && invoiceDetails && (
        <>
          <p>
            <strong>Party Name:</strong> {invoiceDetails[0]?.PartyName}
          </p>
          <p>
            <strong>Description:</strong> {invoiceDetails[0]?.Description}
          </p>
          <p>
            <strong>Invoice Date:</strong> {invoiceDetails[0]?.InvoiceDate}
          </p>
          <p>
            <strong>Pay Mode:</strong> {invoiceDetails[0]?.PayMode}
          </p>

          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            size="small"
            bordered
            footer={footer} // Set the footer
          />
        </>
      )}
    </Modal>
  );
}

export default InvoiceView;
