import React, { useState, useEffect } from "react";
import { Table, Select, Alert, DatePicker, Card, Row, Col } from "antd";
import dayjs from "dayjs";
import { fetchAccountLedger, fetchAccounts } from "../services/ApiServices/apiFunctions";

const { Option } = Select;

interface LedgerEntry {
  voucher_number: number;
  voucher_date: string;
  description: string;
  voucher_type: string;
  pay_mode: string;
  cheque_no: string;
  document_number: string;
  user_id: string;
  account_name: string;
  opening_balance: number;
  debit: number;
  credit: number;
  closing_balance: number;
}

const CashBook: React.FC = () => {
  const [data, setData] = useState<LedgerEntry[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<number>(1);
  const [fromDate, setStartDate] = useState(null);
  const [toDate, setEndDate] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [error, setError] = useState<string | null>(null);
  const [openingBalance, setOpeningBalance] = useState<number>(0);
  const [closingBalance, setClosingBalance] = useState<number>(0);

  useEffect(() => {
    if (selectedAccount && ((fromDate && toDate) || (!fromDate && !toDate))) {
      fetchLedgerData(
        pagination.current,
        pagination.pageSize,
        fromDate ? dayjs(fromDate).format("YYYY-MM-DD") : "",
        toDate ? dayjs(toDate).format("YYYY-MM-DD") : "",
        selectedAccount
      );
    }
  }, [
    pagination.current,
    pagination.pageSize,
    fromDate,
    toDate,
    selectedAccount,
  ]);

  const fetchLedgerData = async (
    page: number,
    pageSize: number,
    fromDate: string,
    toDate: string,
    selectedAccount: number
  ) => {
    setLoading(true);
    setError(null);
    try {
      setOpeningBalance(0);
      setClosingBalance(0);
      const response = await fetchAccountLedger(
        page,
        pageSize,
        fromDate,
        toDate,
        selectedAccount
      );

      if (
        Array.isArray(response.responseObject.ledgerEntries) &&
        response.responseObject.ledgerEntries.length > 0
      ) {
        const ledgerData = response.responseObject.ledgerEntries.map(
          (entry: any) => ({
            ...entry,
            voucher_number: entry.voucher.voucher_number,
            voucher_date: entry.voucher.voucher_date,
            description: entry.voucher.description,
            pay_mode: entry.voucher.pay_mode,
            cheque_no: entry.voucher.cheque_no,
            document_number: entry.voucher.document_number,
          })
        );

        setData(ledgerData);
        setPagination({
          ...pagination,
          total: response.responseObject.pagination.total,
        });
        setOpeningBalance(ledgerData[0].opening_balance);
        const lastIndex = ledgerData.length - 1;
        setClosingBalance(ledgerData[lastIndex].closing_balance);
      } else {
        setData([]);
        setPagination({ ...pagination, total: 0 });
        setOpeningBalance(0);
        setClosingBalance(0);
      }
    } catch (error) {
      setError("An error occurred while fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  const handleAccountChange = (value: number) => {
    setSelectedAccount(value);
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  const handleStartDateChange = (date: any) => {
    setStartDate(date ?? null);
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date ?? null);
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  const columns = [
    { title: "Voucher Number", dataIndex: "voucher_number", key: "voucher_number" },
    { title: "Voucher Date", dataIndex: "voucher_date", key: "voucher_date" },
    { title: "Voucher Type", dataIndex: "voucher_type", key: "voucher_type" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Cheque Number", dataIndex: "cheque_no", key: "cheque_no" },
    {
      title: "Received",
      dataIndex: "debit",
      key: "debit",
      render: (text: number) => (
        <div style={{ textAlign: "right" }}>
          {new Intl.NumberFormat().format(text)}
        </div>
      ),
    },
    {
      title: "Payment",
      dataIndex: "credit",
      key: "credit",
      render: (text: number) => (
        <div style={{ textAlign: "right" }}>
          {new Intl.NumberFormat().format(text)}
        </div>
      ),
    },
    {
      title: "Balance",
      dataIndex: "closing_balance",
      key: "closing_balance",
      render: (text: number) => (
        <div style={{ textAlign: "right", color: text < 0 ? "red" : "black" }}>
          {text < 0
            ? `(${new Intl.NumberFormat().format(Math.abs(text))})`
            : new Intl.NumberFormat().format(text)}
        </div>
      ),
    },
  ];

  return (
    <Card title="Cash Book">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={6}>
          <label>From Date</label>
          <DatePicker
            onChange={handleStartDateChange}
            style={{ width: "100%" }}
            placeholder="From Date"
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <label>To Date</label>
          <DatePicker
            onChange={handleEndDateChange}
            style={{ width: "100%" }}
            placeholder="To Date"
          />
        </Col>
      </Row>

      {error && (
        <Alert
          message={error}
          type="error"
          closable
          style={{ marginBottom: "10px" }}
        />
      )}

      {openingBalance !== null && openingBalance !== undefined && (
        <div
          style={{
            marginBottom: "10px",
            textAlign: "right",
            marginTop: "10px",
            color: openingBalance < 0 ? "red" : "black",
          }}
        >
          Opening Balance:{" "}
          {openingBalance < 0
            ? `(${new Intl.NumberFormat().format(Math.abs(openingBalance))})`
            : new Intl.NumberFormat().format(openingBalance)}
        </div>
      )}

      <div style={{ overflowX: "auto" }}>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={pagination}
          onChange={handleTableChange}
          rowKey="voucher_id"
          size="small"
          bordered
        />
      </div>

      {closingBalance !== null && closingBalance !== undefined && (
        <div
          style={{
            marginBottom: "10px",
            textAlign: "right",
            marginTop: "10px",
            color: closingBalance < 0 ? "red" : "black",
          }}

        >
          Closing Balance:{" "}
          {closingBalance < 0
            ? `(${new Intl.NumberFormat().format(Math.abs(closingBalance))})`
            : new Intl.NumberFormat().format(closingBalance)}
        </div>
      )}
    </Card>
  );
};

export default CashBook;
