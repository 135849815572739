import React, { useState, useEffect } from "react";
import { Table, Input, Alert, DatePicker, Card, Row, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  fetchExpenses,
  fetchExpenseCategories,
} from "../services/ApiServices/apiFunctions";
import dayjs from "dayjs";
import SearchableSelect from "../components/common/SearchableSelect";

const { RangePicker } = DatePicker;

interface DataItem {
  SerialNo: string;
  ExpenseCategory: string;
  Description: string | null;
  Date: string;
  Amount: string;
  category: {
    id: number;
    CategoryName: string;
  };
}

interface Category {
  id: number;
  CategoryName: string;
}

const ViewExpenses: React.FC = () => {
  const [data, setData] = useState<DataItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>("");
  const [grandTotal, setGrandTotal] = useState<number>(0);
  const [categories, setCategories] = useState<Category[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [selectedExpenseCategoryId, setSelectedExpenseCategoryId] =
    useState<number>(0);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [dateRange, setDateRange] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null] | null
  >(null);

  useEffect(() => {
    loadExpenseCategories();
  }, []);

  useEffect(() => {
    fetchTableData(
      pagination.current,
      pagination.pageSize,
      searchText,
      dateRange,
      selectedExpenseCategoryId
    );
  }, [pagination.current, pagination.pageSize, selectedExpenseCategoryId, dateRange, searchText]);

  const fetchTableData = async (
    page: number,
    pageSize: number,
    searchText: string,
    dateRange: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null,
    selectedExpenseCategoryId: number
  ) => {
    setLoading(true);
    setError(null);
    try {
      const fromDate = dateRange
        ? dateRange[0]?.format("YYYY-MM-DD")
        : undefined;
      const toDate = dateRange ? dateRange[1]?.format("YYYY-MM-DD") : undefined;

      // Use the new function to fetch sales invoices
      const response = await fetchExpenses(
        page,
        pageSize,
        searchText,
        fromDate,
        toDate,
        selectedExpenseCategoryId
      );
      setData(response.responseObject.expenses.data);
      setGrandTotal(response.responseObject.totalAmount);
      setPagination({
        ...pagination,
        total: response.responseObject.expenses.total,
      });
    } catch (error) {
      setError("An error occurred while fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const loadExpenseCategories = async () => {
    // Update method name
    setLoading(true);
    try {
      const data = await fetchExpenseCategories(); // Update the API function names
      if (Array.isArray(data.responseObject)) {
        const allOption = { id: 0, CategoryName: "ALL" };
        setCategories([allOption, ...data.responseObject]);
        setError(null);
      } else {
        setError("Error: Invalid data format");
      }
    } catch (error) {
      setError("Error fetching expense categories. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  const handleCategoryChange = (value: number) => {
    setSelectedExpenseCategoryId(value);
    setPagination({ ...pagination, current: 1 });
  };

  const handleSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchText(value);
    setPagination({ ...pagination, current: 1 });
  };

  const handleDateRangeChange = (
    dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null,
    dateStrings: [string, string]
  ) => {
    setDateRange(dates);
    setPagination({ ...pagination, current: 1 });
  };

  const columns = [
    {
      title: "Sr.No",
      key: "serialNo",
      align: "center" as "center",
      render: (text: any, record: DataItem, index: number) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: "Expense Category",
      dataIndex: "category",
      key: "category",
      render: (category: any) => category?.CategoryName,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text: string) => (
        <div style={{ textAlign: "right" }}>{text}</div>
      ),
    },
  ];

  return (
    <Card title="View Expenses">
      {error && (
        <Alert
          message={error}
          type="error"
          closable
          style={{ marginBottom: "10px" }}
        />
      )}
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8}>
          <label htmlFor="search">Search</label>
          <Input
            placeholder="Search..."
            prefix={<SearchOutlined />}
            allowClear
            onChange={handleSearchText}
          />
        </Col>
        <Col xs={24} sm={12} md={8}>
          <label htmlFor="expense-category">Expense Category</label>
          <SearchableSelect
            options={categories}
            placeholder="Select a Category"
            onChange={handleCategoryChange}
            idKey="id"
            nameKey="CategoryName"
          />
        </Col>
        <Col xs={24} sm={12} md={8}>
          <label htmlFor="date-range">Date Range</label>
          <RangePicker
            onChange={handleDateRangeChange}
            style={{ width: "100%" }}
            placeholder={["From Date", "To Date"]}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={pagination}
            onChange={handleTableChange}
            rowKey="id" // Use "id" as the unique identifier
            size="small"
            scroll={{ x: true }}
            bordered
            summary={(tableData) => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="right">
                      <strong>Grand Total</strong>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="right">
                      <strong>{grandTotal}</strong>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default ViewExpenses;
