import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login/Login";
import Register from "../pages/Login/register";
import ChangePasswordForm from "../pages/ChangePasswordForm";
import Logout from "../components/common/LogoutButton/LogoutButton";
import ResultPage from "../components/common/ResultPage";

export const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/permission-denied" element={<ResultPage />} />
      <Route
        path="/token-expired"
        element={
          <ResultPage
            statusCode="error"
            title="Token has been expired!"
            subtitle="Click here to login again."
            homeLink="/login"
          />
        }
      />
      <Route path="/dashboard/*" element={<Dashboard />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/register" element={<Register />} />
      <Route
        path="forgot-password"
        element={
          <ChangePasswordForm
            title="Forgot Password ?"
            showOldPassword={false}
            showToken={true}
            usernameReadOnly={false}
            showNote={true}
          />
        }
      />
    </Routes>
  );
};
